// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBPA89KAUglC5C4VUrCdZ_8rT4OrzXaUUI",
    authDomain: "hs-ai-image-generator.firebaseapp.com",
    projectId: "hs-ai-image-generator",
    storageBucket: "hs-ai-image-generator.appspot.com",
    messagingSenderId: "173176011133",
    appId: "1:173176011133:web:723437637a16aeef9df414"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const Auth = getAuth(app)
export const Provider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)