import React from 'react'
import deflogo from "../logo.png"


const DisplayPost = (props) => {
  const {logo, image, prompt, user} = props.post;

  return (
    <div className="grid">

  <div className="images-item" style={{width: "18rem"}}>
  <img src={image} class="card-img-top" alt={prompt}/>
  <div className="card-body description">
    <div className='card-discription'>
    <img className='email-logo logo' src={logo? logo: deflogo} alt={prompt} />
  <span style={{color: "white",fontSize: "12px", textTransform: "lowercase"}}>{user} </span>
    </div>
  
    <p className="card-text "style={{"fontSize": "14px", color: "white"}}>{prompt}</p>
  </div>
 </div>
 

    </div>
  )
}

export default DisplayPost